import { Error } from '@/src/components/features/Error';
import { FetchError } from '@/src/error/fetchError/FetchError';
import { Props } from '@/src/types/app';

import { NextPage } from 'next';

const Custom404Page: NextPage = () => (
  <Error fetchError={new FetchError(404)} />
);

export const getStaticProps = (): Props => ({
  props: {
    layout: 'none',
  },
});

export default Custom404Page;
